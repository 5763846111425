import React, { useCallback, useEffect, useState } from "react";
import {
  bloodGroup,
  Contract,
  gender,
  Hostel,
  Marital,
  PickupPoint,
  roomno,
  route,
  Shift,
  staffDepartment,
  staffrole,
  VehicleNumber,
} from "../../../../../core/common/selectoption/selectoption";
import CommonSelect, { Option } from "../../../../../core/common/commonSelect";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../../../router/all_routes";
import { DatePicker } from "antd";
import { TagsInput } from "react-tag-input-component";
import StaffBasicInfo from "../../staff-details.tsx/StaffBasicInfo";
import { StaffPayrollType } from "..";
import { fetchAuthenticatedData, postAuthenticatedData, putAuthenticatedData } from "../../../../../redux/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export type PayrollItemType = {
  id: string;
  itemName: string;
  itemType:string;
  defaultAmount: number
}

export type StaffPayrollItemType = {
  id: number,
  payrollItemId: number,
  payroll_item: PayrollItemType,
  amount: number;

}

const EditStaffPayroll = () => {
  const routes = all_routes;
  const staffId = useParams().id
  const user = useSelector((state:any) => state.auth.user)
  const [payrollInfo, setPayrollInfo] = useState<StaffPayrollType>({
    "id":0,"username":"","fullName":"",
    "role":"","active":true,"departmentId":0,
    user_financial_info: {
      "id":0,"employmentType":"","salaryBasic":"0","salaryGross":0,"salaryNet":0,"allowanceTotal":0,"deductionTotal":0,
      "bankName":"","accountName":"","accountNumber":"","iban":"","userId":0
    },
    user_personal_info: {
      "id":0,"dateOfBirth":"","gender":"","maritalStatus":"","fatherName":"","idNumber":"","address":"","city":"","country":"",
      "mobile":"","phone":"","emailAddress":"","userId":0
    },
    staff_payroll_items: []
  })
  const [allowanceForm, setAllowanceForm] = useState({
    item: "",
    amount: 0
  })
  const [deductionForm, setDeductionForm] = useState({
    item: "",
    amount: 0
  })

  const getPayrollInfo = useCallback((cb?:any) => {
    fetchAuthenticatedData('/api/users/' + staffId)
  .then(res => {
      console.log(res)
      setPayrollInfo(res)
      cb()
      // this.setState({user: res.data}, () => {
      //     if(this.state.user.jobs) {
      //         this.state.user.jobs.map(job => {
      //             if(new Date(job.startDate).setHours(0) < new Date() && new Date(job.endDate).setHours(24) > new Date()) {
      //                 this.setState({currentJobTitle: job.jobTitle})
      //             }
      //         })
      //     }
      // })
  })
  .catch(err => {
      console.log(err)
  })
  }, [staffId])

  useEffect(() => {
    getPayrollInfo()
  }, [getPayrollInfo])

  const [payrollItems, setPayrollItems] = useState<PayrollItemType[]>([])

  const getPayrollItems = () => {
    fetchAuthenticatedData('/api/paymentItems/items')
    .then((res:any) => {
      setPayrollItems(res)
    })
    .catch((err:any) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getPayrollItems()
  }, [])

  const handleAllowanceFormChange = (e: any) => {
    setAllowanceForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  const handleDeductionFormChange = (e: any) => {
    setDeductionForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  const allowances:PayrollItemType[] = payrollItems.filter((p:any) => p.itemType === 'Allowance')
  const deductions:PayrollItemType[] = payrollItems.filter((p:any) => p.itemType === 'Deduction')

  const handlePayrollChange = (key:string,value:string) => {
    setPayrollInfo(prev => ({
      ...prev,
      user_financial_info: {
        ...prev.user_financial_info,
        [key]: value
      }
    }))
  }
  
  const staffAllowances = payrollInfo.staff_payroll_items?.filter((item:any) => item.payroll_item.itemType === "Allowance")
  const staffDeductions = payrollInfo.staff_payroll_items?.filter((item:any) => item.payroll_item.itemType === "Deduction")

  const totalAllowance = staffAllowances?.reduce((a, b) => a + b.amount, 0) || 0
  const totalDeduction = staffDeductions?.reduce((a, b) => a + b.amount, 0) || 0

  const handleSubmitSalaryDetails = (e?:any) => {
    e?.preventDefault()
    let userFinancialInfo = {
      ...payrollInfo.user_financial_info,
      salaryBasic:  payrollInfo.user_financial_info.salaryBasic,
      salaryGross: parseFloat(payrollInfo.user_financial_info.salaryBasic) + totalAllowance,
      allowanceTotal: totalAllowance,
      deductionTotal: totalDeduction,
      salaryNet: (parseFloat(payrollInfo.user_financial_info.salaryBasic) + totalAllowance) - totalDeduction,
  };
    putAuthenticatedData("/api/financialInformations/"+ userFinancialInfo?.userId , userFinancialInfo)
    .then(res => {
      if(res.message) {
        toast(res.message)
      } else {
        toast("Salary Info Updated Successfully")
      }
    }).catch(err => {
      toast(err.message, { type : 'error'})
    })
  }

  const saveAllowance = (e:any) => {
    e.preventDefault()
    postAuthenticatedData('/api/staffPayrollItems', {
      userId: payrollInfo.id,
      itemId: allowanceForm.item,
      amount: allowanceForm.amount,
      totalAllowances : parseFloat(totalAllowance.toString()) + parseFloat(allowanceForm.amount.toString()),
      totalDeductions: totalDeduction,
      salaryBasic: payrollInfo.user_financial_info.salaryBasic
    }).then(res => {
      getPayrollInfo()
      // handleSubmitSalaryDetails()
      if(res.message) {
        toast(res.message)
      } else {
        toast("Allowance added successfully")

      }
    }).catch(err => {
      toast(err.message, { type : 'error'})
    })
  }

  const saveDeduction = (e:any) => {
    e.preventDefault()
    postAuthenticatedData('/api/staffPayrollItems', {
      userId: payrollInfo.id,
      itemId: deductionForm.item,
      amount: deductionForm.amount,
      totalAllowances : totalAllowance,
      totalDeductions: parseFloat(totalDeduction.toString()) + parseFloat(deductionForm.amount.toString()),
      salaryBasic: payrollInfo.user_financial_info.salaryBasic
    }).then(res => {
      getPayrollInfo()
      // handleSubmitSalaryDetails()
        if(res.message) {
        toast(res.message)
      } else {
        toast("Deduction added successfully")
      }
    }).catch(err => {
      toast(err.message, { type : 'error'})
    })
  }

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="mb-1">Edit Staff Payroll</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">HRM</li>
                    <li className="breadcrumb-item">Staff Payroll</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit Staff Payroll
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-3">
                <StaffBasicInfo basicInfo={payrollInfo} />
              </div>
              <div className="col-md-9">
                <form >
                
                  {/* Payroll */}
                  <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Payroll</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                  
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Basic Salary</label>
                            <input type="text" className="form-control" 
                            value={payrollInfo?.user_financial_info?.salaryBasic?.toLocaleString()||""}
                            onChange={(event: any) => handlePayrollChange('salaryBasic', event.target.value)}
                             />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Employment Type</label>
                            <CommonSelect
                              className="select"
                              options={Contract}
                              value={{label: payrollInfo.user_financial_info.employmentType, value: payrollInfo.user_financial_info.employmentType}}
                              handleChange={(option:any) => handlePayrollChange('employmentType', option.value)}
                            />
                          </div>
                        </div>
                    {/* {JSON.stringify(payrollInfo.user_financial_info)} */}
                      </div>
                      <div className="text-end">
                    {/* <button type="button" className="btn btn-light me-3">
                      Cancel
                    </button> */}
                    <button onClick={handleSubmitSalaryDetails} className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                    <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Allowances</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Select Allowance Type</label>
                            <CommonSelect
                              className="select"
                              options={allowances.map((i:any) => ({label: i.itemName, value: i.id}))}
                              value={{label: allowances.find((all:PayrollItemType) => all.id === allowanceForm.item)?.itemName||"", value: allowanceForm.item.toString()||""}}
                              handleChange={(e: Option|null) => setAllowanceForm(prev => ({...prev, item: e?.value||""}))}
                              // defaultValue={Contract[0]}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Amount</label>
                            <input type="text" className="form-control" value={allowanceForm.amount} onChange={handleAllowanceFormChange} name="amount" />
                          </div>
                        </div>
                    
                      </div>

                      <center>
                      <button className="btn btn-primary btn-sm" onClick={saveAllowance}>Add</button>
                      </center>

                      {/* {JSON.stringify(allowanceForm)} */}

                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Allowance Type</th>
                            <th>Amount</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffAllowances?.map((item:any) => 
                          <tr>
                            <td>{item.payroll_item.itemName}</td>
                            <td className="text-end">{item?.amount?.toLocaleString()||""}</td>
                            <td><button className='btn btn-danger btn-sm'>Remove</button></td>
                            </tr>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                    </div>
                    <div className="col-md-6">
                    <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Deductions</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Select Deduction Type</label>
                            <CommonSelect
                              className="select"
                              options={deductions.map((i:any) => ({label: i.itemName, value: i.id}))}
                              value={{label: deductions.find((all:PayrollItemType) => all.id === deductionForm.item)?.itemName||"", value: deductionForm.item.toString()||""}}
                              handleChange={(e: Option|null) => setDeductionForm(prev => ({...prev, item: e?.value||""}))}
                              // defaultValue={Contract[0]}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Amount</label>
                            <input type="text" className="form-control" value={deductionForm.amount} onChange={handleDeductionFormChange} name="amount" />
                          </div>
                        </div>
                    
                      </div>

                      <center>
                      <button className="btn btn-primary btn-sm" onClick={saveDeduction}>Add</button>
                      </center>

                      {/* {JSON.stringify(payrollInfo.staff_payroll_items?.filter((item:any) => item.payroll_item.itemType === "Deduction"))} */}

                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Deduction Type</th>
                            <th>Amount</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffDeductions?.map((item:any) => 
                          <tr>
                            <td>{item.payroll_item.itemName}</td>
                            <td className="text-end">{item?.amount?.toLocaleString()||""}</td>
                            <td><button className='btn btn-danger btn-sm'>Remove</button></td>
                          </tr>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                    </div>
                  </div>


                    <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Total Salary Details</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                  
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Gross Salary</label>
                            <input type="text" className="form-control text-end" disabled value={payrollInfo?.user_financial_info?.salaryGross?.toLocaleString()||""} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Total Allowances</label>
                            <input type="text" className="form-control text-end" disabled value={payrollInfo?.user_financial_info?.allowanceTotal?.toLocaleString()||""}/>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Total Deductions</label>
                            <input type="text" className="form-control text-end" disabled value={payrollInfo?.user_financial_info?.deductionTotal?.toLocaleString()||""}/>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Net Salary</label>
                            <input type="text" className="form-control text-end" disabled value={payrollInfo?.user_financial_info?.salaryNet?.toLocaleString()||""}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* /Payroll */}
               
                  {/* Bank Details */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-map">Bank Account Detail</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="Kevin"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Number</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={178849035684}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="Bank of America"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="BOA83209832"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Branch Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="Cincinnati"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Bank Details */}
                 
                  
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default EditStaffPayroll;
