import React, { useEffect, useRef, useState } from "react";
import { leave } from "../../../../core/data/json/list_leaves";
import { TableData } from "../../../../core/data/interface";
import Table from "../../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import CommonSelect from "../../../../core/common/commonSelect";
import { activeList, leaveType } from "../../../../core/common/selectoption/selectoption";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";
import { DatePicker, DatePickerProps } from "antd";
import { fetchAuthenticatedData, postAuthenticatedData, putAuthenticatedData } from "../../../../redux/api";
import { useSelector } from "react-redux";
import moment from "moment";

const ListLeaves = () => {
  const routes = all_routes;
  const user = useSelector((state:any) => state.auth.user)
    const data = leave;
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const [leaveapplication,setLeaveApplication] = useState([])
 
  const [form, setForm] = useState({
    leaveType:'',
    startDate:'',
    endDate:'',
    reason:''
  })
  const handleSelectchange =(key:string, value:string|string[]) => {
    setForm(prev => ({...prev, [key]: value }))
  }

  const handleChange = (e: any) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = (e:any) => {
    // this.setState({ hasError: false, errorMsg: "", completed: false });

    let userform = {
      type: form.leaveType,
      startDate: moment(form.startDate, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss"), //1234,
      endDate:  moment(form.endDate, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss"),
      reason: form.reason,
      userId:user.id,
      status:"Pending"

    };

    e.preventDefault();
    postAuthenticatedData("/api/applications",userform)
      .then((res) => {
        
  
                // postAuthenticatedData( "/api/jobs/",job)
                //   .then((res) => {
                    alert("success")
                    // this.setState({ completed: true });
                    // window.scrollTo(0, 0);
                  // })
                  // .catch((err) => {
                  //   console.log(err)
                  // //   this.setState({
                  // //     hasError: true,
                  // //     errMsg: err.response.data.message,
                  // //   });
                  // //   window.scrollTo(0, 0);
                  // });
            
              //   this.setState({
              //     hasError: true,
              //     errMsg: err.response.data.message,
              //   });
              //   window.scrollTo(0, 0);
            
         
          //   this.setState({
          //     hasError: true,
          //     errMsg: err.response.data.message,
          //   });
          //   window.scrollTo(0, 0);
        
      })
      .catch((err) => {
        // this.setState({ hasError: true, errMsg: err.response.data.message });
        // window.scrollTo(0, 0);
        console.log(err)
      });
  };
  const getLeavelist = () => {
    fetchAuthenticatedData('/api/applications/user/'+ user.id)
    .then(res => {
      setLeaveApplication(res)
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getLeavelist()
  }, [])



    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        render: (text: string, record: any, index: number) => (
          <>
           <Link to="#" className="link-primary">{record.id}</Link>
          </>
        ),
      },
      {
        title: "Leave Type",
        dataIndex: "leaveType",
        render: (text: string, record: any, index: number) => (
          <span>
           {record.type}
          </span>
        ),
        sorter: (a: TableData, b: TableData) => a.leaveType.length - b.leaveType.length,
      },
  
      {
        title: "Start Date",
        dataIndex: "startDate",
        render: (text: string, record: any, index: number) => (
          <span>
           {moment(record.startDate).format("DD MMMM YYYY")}
          </span>
        ),
        sorter: (a: TableData, b: TableData) => a.leaveType.length - b.leaveType.length,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        render: (text: string, record: any, index: number) => (
          <span>
           {moment(record.endDate).format("DD MMMM YYYY")}
          </span>
        ),
        sorter: (a: TableData, b: TableData) => a.leaveType.length - b.leaveType.length,
      },
      {
        title: "Reason (comment)",
        dataIndex: "reason",
        render: (text: string, record: any, index: number) => (
          <span>
           {record.reason}
          </span>
        ),
        sorter: (a: TableData, b: TableData) => a.leaveType.length - b.leaveType.length,
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text: string) => (
            <>
            {text === "Active" ? (
              <span
                className="badge badge-soft-success d-inline-flex align-items-center"
              >
                <i className='ti ti-circle-filled fs-5 me-1'></i>{text}
              </span>
            ):
            (
              <span
                className="badge badge-soft-danger d-inline-flex align-items-center"
              >
                <i className='ti ti-circle-filled fs-5 me-1'></i>{text}
              </span>
            )}
          </>
        ),
        sorter: (a: any, b: any) => a.status.length - b.status.length,
      },
      // {
      //   title: "Action",
      //   dataIndex: "action",
      //   render: () => (
      //     <>
      //       <div className="dropdown">
      //         <Link
      //           to="#"
      //           className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
      //           data-bs-toggle="dropdown"
      //           aria-expanded="false"
      //         >
      //           <i className="ti ti-dots-vertical fs-14" />
      //         </Link>
      //         <ul className="dropdown-menu dropdown-menu-right p-3">
      //           <li>
      //             <Link
      //               className="dropdown-item rounded-1"
      //               to="#"
      //               data-bs-toggle="modal"
      //               data-bs-target="#edit_leaves"
      //             >
      //               <i className="ti ti-edit-circle me-2" />
      //               Edit
      //             </Link>
      //           </li>
      //           <li>
      //             <Link
      //               className="dropdown-item rounded-1"
      //               to="#"
      //               data-bs-toggle="modal"
      //               data-bs-target="#delete-modal"
      //             >
      //               <i className="ti ti-trash-x me-2" />
      //               Delete
      //             </Link>
      //           </li>
      //         </ul>
      //       </div>
      //     </>
      //   ),
      // },
    ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">My Leave Applications</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">HRM</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leave Type
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary d-flex align-items-center"
                    data-bs-toggle="modal"
                    data-bs-target="#add_leaves"
                  >
                    <i className="ti ti-square-rounded-plus me-2" />
                    Apply for Leave
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Filter Section */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Leave Application List</h4>
                {/* {JSON.stringify(leaveapplication)} */}
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                      <form >
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Leave type</label>
                               
                                <CommonSelect
                                  className="select"
                                  options={leaveType}
                                />
                              </div>


                            </div>

                            {/* <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Leave Type</label>
                               
                                <CommonSelect
                                  className="select"
                                  options={leaveType}
                                />
                              </div>

                              
                            </div> */}
                            {/* </div> */}
                            <div className="col-md-12">
                              <div className="mb-0">
                                <label className="form-label">Status</label>
                                <CommonSelect
                                  className="select"
                                  options={activeList}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1 active"
                        >
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1"
                        >
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1"
                        >
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1"
                        >
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* List Leaves List */}
                  <Table columns={columns} dataSource={leaveapplication} Selection={true}/>
                {/* / List Leaves List */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Add Leaves */}
        <div className="modal fade" id="add_leaves">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Apply for Leave</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form >
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                    
                      <div className="mb-3">
                      
                        <label className="form-label">Leave Type</label>
                        {/* {JSON.stringify(form)} */}
                        <CommonSelect
                                  className="select"
                                  options={leaveType}
                                  value={{label:form.leaveType, value:form.leaveType}}
                                  handleChange={(value:any) => handleSelectchange("leaveType", value.value)}
                                  required
                                />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Start date</label>
                        <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            placeholder="Pick start date"
                            onChange={(date, dateString):DatePickerProps['onChange'] => {
                              handleSelectchange('startDate', dateString)
                              return;
                            
                            }}
                            required
                          />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">End Date</label>
                        <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            placeholder="Pick end date"
                          
                            onChange={(date, dateString):DatePickerProps['onChange'] => {
                              handleSelectchange('endDate', dateString)
                              return;
                            
                            }}
                            required
                          />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Reason for request</label>
                        <input type="text"
                         className="form-control" 
                         name="reason"
                         value={form.reason}
                         onChange={handleChange}/>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <button className="btn btn-primary" 
                  data-bs-dismiss="modal"
                  onClick={handleSubmit}
                  >
                   Apply for Leave
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Add Leaves */}
        {/* Edit Leaves */}
        <div className="modal fade" id="edit_leaves">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Leave Type</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form >
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Leave Type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Leave Type"
                          defaultValue="Medical Leave"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link to="#" className="btn btn-primary" data-bs-dismiss="modal">
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Edit Leaves */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form >
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link to="#" className="btn btn-danger" data-bs-dismiss="modal">
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default ListLeaves;
