export const blog_comments_data = [
  {
    key: '1',
    comment: 'I absolutely loved this article!... The insights',
    date: '25 May 2024',
    review: 5.0,
    blog: '5 Effective Strategies to Boost Your Productivity',
    author: 'Rubiaana',
    status: 'published',
  },
  {
    key: '2',
    comment: 'Self-improvement is a continuous journey',
    date: '15 May 2024',
    review: 5.0,
    blog: 'Personal development and self-improvement',
    author: 'Jordan',
    status: 'published',
  },
  {
    key: '3',
    comment: 'Prioritizing tasks makes a huge difference.',
    date: '12 May 2024',
    review: 5.0,
    blog: 'Prioritize Your Tasks',
    author: 'Daniel',
    status: 'published',
  },
  {
    key: '4',
    comment: 'Turning off notifications helped me focus',
    date: '27 Apr 2024',
    review: 5.0,
    blog: 'How to eliminate Eliminate Distractions',
    author: 'Letitia',
    status: 'published',
  },
  {
    key: '5',
    comment: 'Regular breaks improve my retention.',
    date: '16 Apr 2024',
    review: 5.0,
    blog: 'Steps to Take Regular Breaks before learning',
    author: 'Henrita',
    status: 'published',
  },
  {
    key: '6',
    comment: 'Using a planner keeps me on track',
    date: '10 Apr 2024',
    review: 5.0,
    blog: 'Using Time Management Techniques',
    author: 'Ziatan',
    status: 'published',
  },
  {
    key: '7',
    comment: 'Great strategies for schools!',
    date: '24 Mar 2024',
    review: 5.0,
    blog: 'Effective Strategies for School Administration',
    author: 'Jason',
    status: 'published',
  },
  {
    key: '8',
    comment: 'Excited to learn about tech in schools!',
    date: '18 Mar 2024',
    review: 5.0,
    blog: 'Implementing Technology in School Management',
    author: 'Gloria',
    status: 'published',
  },
  {
    key: '9',
    comment: 'Communication is key in any organization',
    date: '11 Mar 2024',
    review: 5.0,
    blog: 'Building Strong Communication Channels in Schools',
    author: 'Elizabeth',
    status: 'published',
  },
  {
    key: '10',
    comment: 'Improving staff training sounds promising!',
    date: '20 Feb 2024',
    review: 5.0,
    blog: 'Effective Staff Training and Development in Schools',
    author: 'Michael',
    status: 'published',
  },
  {
    key: '11',
    comment: 'Safety is paramount in schools',
    date: '19 Feb 2024',
    review: 5.0,
    blog: 'Ensuring Safety and Security Measures in Schools',
    author: 'Richard',
    status: 'published',
  },
  ];