export const classSection= [
	{
		id :"SE167645",
		sectionName: "A",
		status: "Active",
		key:1

	},
	{
		id :"SE167644",
		sectionName: "B",
		status: "Active",
		key:2
	},
	{
		id: "SE167643",
		sectionName: "C",
		status: "Active",
		key:3
	},
	{
		id :"SE167642",
		sectionName: "D",
		status: "Active",
		key:4
	},
	{
		id: "SE167641",
		sectionName: "E",
		status: "Active",
		key:5
	},
	{
		id: "SE167640",
		sectionName: "F",
		status: "Active",
		key:6
	},
	{
		id: "SE167639",
		sectionName: "G",
		status: "Active",
		key:7
	},
	{
		id: "SE167638",
		sectionName: "H",
		status: "Active",
		key:8
	},
	{
		id: "SE167637",
		sectionName: "I",
		status: "Active",
		key:9
	},
	{
		id: "SE167636",
		sectionName: "J",
		status: "Active",
		key:10
	}
];
