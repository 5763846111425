export const languageSet = [
    {
        language: "English",
        code: "en",
        rtl: "",
        status: "Active",
    },
    {
        language: "Arabic",
        code: "ar",
        rtl: "",
        status: "Active",
    },
    {
        language: "Chinese",
        code: "zh",
        rtl: "",
        status: "Active",
    },
    {
        language: "Hindi",
        code: "hi",
        rtl: "",
        status: "Active",
    }
]
