export const salarydata = [
  {
    key: "1",
    id: "8198",
    Salary_For: "Apr - 2024",
    date: "04 May 2024",
    Payment_Method: "Cash",
    Net_Salary: "$20,000",
  },
  {
    key: "2",
    id: "8197",
    Salary_For: "Mar - 2024",
    date: "05 Apr 2024",
    Payment_Method: "Cheque",
    Net_Salary: "$19,000",
  },
  {
    key: "3",
    id: "8196",
    Salary_For: "Feb - 2024",
    date: "05 Mar 2024",
    Payment_Method: "Cash",
    Net_Salary: "$19,500",
  },
  {
    key: "4",
    id: "8198",
    Salary_For: "Jan - 2024",
    date: "06 Feb 2024",
    Payment_Method: "Cash",
    Net_Salary: "$20,000",
  },
  {
    key: "5",
    id: "8194",
    Salary_For: "Dec - 2023",
    date: "03 Jan 2024",
    Payment_Method: "Cheque",
    Net_Salary: "$19,480",
  },
  {
    key: "6",
    id: "8193",
    Salary_For: "Nov - 2023",
    date: "05 Dec 2023",
    Payment_Method: "Cheque",
    Net_Salary: "$19,480",
  },
  {
    key: "7",
    id: "8192",
    Salary_For: "Oct - 2023",
    date: "03 Nov 2023",
    Payment_Method: "Cheque",
    Net_Salary: "$19,480",
  },
  {
    key: "8",
    id: "8191",
    Salary_For: "Sep - 2023",
    date: "04 Oct 2023",
    Payment_Method: "Cheque",
    Net_Salary: "$18,000",
  },
  {
    key: "9",
    id: "8190",
    Salary_For: "Aug - 2023",
    date: "06 Sep 2023",
    Payment_Method: "Cheque",
    Net_Salary: "$20,000",
  },
  {
    key: "10",
    id: "8189",
    Salary_For: "Jul - 2023",
    date: "05 Aug 2023",
    Payment_Method: "Cheque",
    Net_Salary: "$20,000",
  },
];
