import React, { useCallback, useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { fetchAuthenticatedData } from "../../../../redux/api";
import { AddStaffForm } from "../add-staff";
import StaffBasicInfo from "./StaffBasicInfo";
import { StaffPayrollType } from "../staff-payroll.tsx";

const StaffDetails = () => {
  const staffId = useParams().id
  const routes = all_routes
  const [staffView,setStaffView ] = useState<StaffPayrollType>({
    "id":0,"username":"","fullName":"",
    "role":"","active":true,"departmentId":0,
    user_financial_info: {
      "id":0,"employmentType":"","salaryBasic":"0","salaryGross":0,"salaryNet":0,"allowanceTotal":0,"deductionTotal":0,
      "bankName":"","accountName":"","accountNumber":"","iban":"","userId":0
    },
    user_personal_info: {
      "id":0,"dateOfBirth":"","gender":"","maritalStatus":"","fatherName":"","idNumber":"","address":"","city":"","country":"",
      "mobile":"","phone":"","emailAddress":"","userId":0
    },
  })

  const getStaffInfo = useCallback(() => {
    fetchAuthenticatedData ('/api/users/' + staffId)
  
  .then(res => {
      // let user = res
      setStaffView(res)

   
  })
  .catch(err => {
      console.log(err)
  })
  },[staffId])

  
  useEffect(() => {
    getStaffInfo()
  }, [getStaffInfo])

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              {/* Page Header */}
              <div className="col-md-12">
                <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                  <div className="my-auto mb-2">
                    <h3 className="page-title mb-1">Staff Details</h3>
                    <nav>
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <Link to={routes.adminDashboard}>Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to={routes.studentList}>HRM</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Staff Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="d-flex my-xl-auto right-content align-items-center  flex-wrap">
                    <Link
                      to={routes.editStaff+"/"+staffId}
                      className="btn btn-primary d-flex align-items-center mb-2"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit Staff
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              
              <div className="col-xxl-3 col-lg-4 theiaStickySidebar">
              <StaffBasicInfo basicInfo={staffView} />
              </div>
              <div className="col-xxl-9 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                      <li>
                        <Link
                          to={routes.staffDetails+"/"+staffId}
                          className="nav-link active"
                        >
                          <i className="ti ti-info-square-rounded me-2" />
                          Basic Details
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffPayroll+"/"+staffId} className="nav-link">
                          <i className="ti ti-file-dollar me-2" />
                          Payroll
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffLeave+"/"+staffId} className="nav-link">
                          <i className="ti ti-calendar-due me-2" />
                          Leaves
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffsAttendance+"/"+staffId} className="nav-link">
                          <i className="ti ti-calendar-due me-2" />
                          Attendance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  {/* Address */}
                  <div className="col-xxl-6 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <h5>Address</h5>
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                          <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                            <i className="ti ti-map-pin-up" />
                          </span>
                          <div>
                            <p className="mb-1 fw-medium text-dark ">
                              Current Address
                            </p>
                            <p>{staffView?.user_personal_info?.address}</p>
                          </div>
                        </div>
                        {/* <div className="d-flex align-items-center">
                          <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                            <i className="ti ti-map-pins" />
                          </span> */}
                          {/* <div>
                            <p className="mb-1 fw-medium text-dark ">
                              Permanent Address
                            </p>
                            <p>{staffView?.user_personal_info?.id}</p>
                          </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  {/* /Address */}
                  {/* Documents */}
                  <div className="col-xxl-6 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <h5>Documents</h5>
                      </div>
                      <div className="card-body">
                        <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                          <div className="d-flex align-items-center overflow-hidden">
                            <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                              <i className="ti ti-pdf fs-15" />
                            </span>
                            <div className="ms-2">
                              <p className="text-truncate fw-medium text-dark ">
                                Resume.pdf
                              </p>
                            </div>
                          </div>
                          <Link to="#" className="btn btn-dark btn-icon btn-sm">
                            <i className="ti ti-download" />
                          </Link>
                        </div>
                        <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between p-2">
                          <div className="d-flex align-items-center overflow-hidden">
                            <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                              <i className="ti ti-pdf fs-15" />
                            </span>
                            <div className="ms-2">
                              <p className="text-truncate fw-medium text-dark ">
                                Joining Letter.pdf
                              </p>
                            </div>
                          </div>
                          <Link to="#" className="btn btn-dark btn-icon btn-sm">
                            <i className="ti ti-download" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Documents */}
                  {/* Bank Details */}
                  <div className="col-xxl-12 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <h5>Bank Details</h5>
                      </div>
                      <div className="card-body pb-1">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <p className="mb-1 fw-medium text-dark ">
                                Account Name
                              </p>
                              <p>{staffView?.user_financial_info?.accountName}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <p className="mb-1 fw-medium text-dark ">
                                Account Number
                              </p>
                              <p>{staffView?.user_financial_info?.accountNumber}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <p className="mb-1 fw-medium text-dark ">
                                Bank Name
                              </p>
                              <p>{staffView?.user_financial_info?.bankName}</p>
                            </div>
                          </div>
                          {/* <div className="col-md-4">
                            <div className="mb-3">
                              <p className="mb-1 fw-medium text-dark ">
                                Branch
                              </p>
                              <p>Cincinnati</p>
                            </div>
                          </div> */}
                          {/* <div className="col-md-4">
                            <div className="mb-3">
                              <p className="mb-1 fw-medium text-dark ">IFSC</p>
                              <p>BOA83209832</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Bank Details */}
                  {/* Other Info */}
                  {/* <div className="col-xxl-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Other Info</h5>
                      </div>
                      <div className="card-body">
                        <p>
                          Depending on the specific needs of your organization
                          or system, additional information may be collected or
                          tracked. It's important to ensure that any data
                          collected complies with privacy regulations and
                          policies to protect students' sensitive information.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* /Other Info */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Login Details */}
        <div className="modal fade" id="login_detail">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Login Details</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="student-detail-info">
                  <span className="student-img">
                    <ImageWithBasePath
                      src="assets/img/teachers/teacher-01.jpg"
                      alt="img"
                    />
                  </span>
                  <div className="name-info">
                    <h6>
                      Teresa <span>III, A</span>
                    </h6>
                  </div>
                </div>
                <div className="table-responsive custom-table no-datatable_length">
                  <table className="table datanew">
                    <thead className="thead-light">
                      <tr>
                        <th>User Type</th>
                        <th>User Name</th>
                        <th>Password </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Teacher</td>
                        <td>teacher20</td>
                        <td>teacher@53</td>
                      </tr>
                      <tr>
                        <td>Parent</td>
                        <td>parent53</td>
                        <td>parent@53</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Login Details */}
      </>
    </div>
  );
};

export default StaffDetails;
