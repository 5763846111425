export const faq_data = [
  {
    key: "1",
    id: "Q354869",
    questions: "How do I reset my password?",
    answers: "How do I reset my password?",
    category: "Account",
  },
  {
    key: "2",
    id: "Q354868",
    questions: "How do I update my contact information?",
    answers: 'You can update your contact information in the "My Account"',
    category: "Account",
  },
  {
    key: "3",
    id: "Q354867",
    questions: "How can I contact the IT support team?",
    answers: "Contact support via email at support@example.com",
    category: "Support",
  },
  {
    key: "4",
    id: "Q354866",
    questions: "Where can I find information about school events?",
    answers: "Information is available on the website's Events page",
    category: "Events",
  },
  {
    key: "5",
    id: "Q354865",
    questions: "How do I access online learning resources?",
    answers: "Access resources through the school's LMS",
    category: "Academic",
  },
  {
    key: "6",
    id: "Q354864",
    questions: "Where can I find information about school fees?",
    answers: "Information is available on the Fees & Payments page",
    category: "Finance",
  },
  {
    key: "7",
    id: "Q354863",
    questions: "How do I register for extracurricular activities?",
    answers: "Register through the school portal's Extracurriculars section.",
    category: "Academic",
  },
  {
    key: "8",
    id: "Q354862",
    questions: "Can I volunteer at the school?",
    answers: "Yes, volunteering opportunities are available at the school.",
    category: "General",
  },
  {
    key: "9",
    id: "Q354861",
    questions: "What are the school's hours of operation?",
    answers: "School hours vary by department and are typically posted",
    category: "Academic",
  },
  {
    key: "10",
    id: "Q354860",
    questions: "How can I request a tour of the school campus?",
    answers: "Contact the admissions office to schedule a tour with a staff",
    category: "General",
  },
];
