export const accounts_invoices_data = [
  {
    key: '1',
    invoiceNumber: 'INV681537',
    date: '25 Apr 2024',
    description: 'April Month Fees',
    amount: '$15,000',
    paymentMethod: 'Cash',
    dueDate: '30 Apr 2024',
    status: 'Paid',
  },
  {
    key: '2',
    invoiceNumber: 'INV681536',
    date: '29 Apr 2024',
    description: 'STEM Program Grant',
    amount: '$20,000',
    paymentMethod: 'Credit',
    dueDate: '05 May 2024',
    status: 'Overdue',
  },
  {
    key: '3',
    invoiceNumber: 'INV681535',
    date: '11 May 2024',
    description: 'Alumni Scholarship',
    amount: '$1,000',
    paymentMethod: 'Cash',
    dueDate: '16 May 2024',
    status: 'Paid',
  },
  {
    key: '4',
    invoiceNumber: 'INV681534',
    date: '16 May 2024',
    description: 'Uniform Sales',
    amount: '$10,500',
    paymentMethod: 'Cash',
    dueDate: '21 May 2024',
    status: 'Pending',
  },
  {
    key: '5',
    invoiceNumber: 'INV681533',
    date: '21 May 2024',
    description: 'Event Parking Fees',
    amount: '$8,000',
    paymentMethod: 'Cash',
    dueDate: '26 May 2024',
    status: 'Paid',
  },
  {
    key: '6',
    invoiceNumber: 'INV681532',
    date: '14 Jun 2024',
    description: 'Football Season Pass',
    amount: 7000,
    paymentMethod: 'Credit',
    dueDate: '19 Jun 2024',
    status: 'Paid',
  },
  {
    key: '7',
    invoiceNumber: 'INV681531',
    date: '20 Jun 2024',
    description: 'Summer Reading Sale',
    amount: 3000,
    paymentMethod: 'Cash',
    dueDate: '25 Jun 2024',
    status: 'Overdue',
  },
  {
    key: '8',
    invoiceNumber: 'INV681530',
    date: '24 Jun 2024',
    description: 'Library Donation',
    amount: 2000,
    paymentMethod: 'Cash',
    dueDate: '29 Jun 2024',
    status: 'Paid',
  },
  {
    key: '9',
    invoiceNumber: 'INV681529',
    date: '08 Jul 2024',
    description: 'Cafeteria Income',
    amount: 15000,
    paymentMethod: 'Credit',
    dueDate: '13 Jul 2024',
    status: 'Paid',
  },
  {
    key: '10',
    invoiceNumber: 'INV681537',
    date: '13 Jul 2024',
    description: 'Daily Visitor Parking',
    amount: 4000,
    paymentMethod: 'Cash',
    dueDate: '18 Jul 2024',
    status: 'Pending',
  }
  ];
  