export const CURRENCY:string = 'NGN'

export const APP_ACCESS = [
    {
        appName: "HR Suite",
        modules: [
            {
                title: 'Dashboard',
                route: '/index'
            },
            {
                title: 'Staffs',
                route: '/hrm/staff'
            },
            {
                title: 'Departments',
                route: '/hrm/departments'
            },
            {
                title: 'Designation',
                route: '/hrm/designation'
            },
            {
                title: 'Designation',
                route: '/hrm/designation'
            },
            {
                title: "Leaves",
                route: '',
                subModules: [
                    {
                        title: 'My Leave Applications',
                        route: '/hrm/list-leaves'
                    },
                    {
                        title: 'Leave Applications',
                        route: '/hrm/application-list'
                    }
                ]
            },
            {
                title: 'Payroll Setup',
                route: '/hrm/payroll'
            },
            {
                title: 'Payroll Items',
                route: '/hrm/payroll-items'
            },
            {
                title: 'Process Payroll',
                route: '/hrm/payroll-items'
            },
            {
                title: 'Payroll Items',
                route: '/hrm/approve-payroll'
            },
            {
                title: "Transactions",
                route: '/membership-transactions'
            }

        ]
    }
]