import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {}
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      // console.log(payload, 'payload')
      state.user = payload;
    },
    logout: (state, { payload }) => {
      state.user = {};
    },

  },
});

export const { setUser, logout } =
  authSlice.actions;

export default authSlice.reducer;
