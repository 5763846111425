import React, { useCallback, useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import Table from "../../../../core/common/dataTable/index";
import { staffpayroll } from "../../../../core/data/json/staff-payroll";
import { TableData } from "../../../../core/data/interface";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { CURRENCY } from "../../../../core/data/constants";
import { fetchAuthenticatedData } from "../../../../redux/api";
import StaffBasicInfo, { UserPersonalInfo } from "../staff-details.tsx/StaffBasicInfo";
import { StaffPayrollItemType } from "./edit-staff-payroll";
import moment from "moment";

type UserFinancialInfo = {
  "id":number;"employmentType":string;"salaryBasic":string;"salaryGross":number;
  "salaryNet":number;"allowanceTotal":number;"deductionTotal":number;"bankName":string;
  "accountName":string;"accountNumber":string;"iban":string;"userId":number
}

type UserDepartment = {
  departmentName:string;
  id:number
}

type DesignationType = {
  title:string,
  id: string|null
}

export type StaffPayrollType = {
  "id":number,"username":string,"fullName":string,
    "role":string,"active":boolean,"departmentId":number,
  user_financial_info: UserFinancialInfo,
  user_personal_info: UserPersonalInfo,
  staff_payroll_items?: StaffPayrollItemType[],
  department?: UserDepartment,dateofJoining?:string,
  bloodGroup?:string;designation?: DesignationType;
}

const StaffPayRoll = () => {
  const routes = all_routes;
  const data = staffpayroll;
  const staffId = useParams().id
  const [payrollInfo, setPayrollInfo] = useState<StaffPayrollType>({
    "id":0,"username":"","fullName":"",
    "role":"","active":true,"departmentId":0,
    user_financial_info: {
      "id":0,"employmentType":"","salaryBasic":"0","salaryGross":0,"salaryNet":0,"allowanceTotal":0,"deductionTotal":0,
      "bankName":"","accountName":"","accountNumber":"","iban":"","userId":0
    },
    user_personal_info: {
      "id":0,"dateOfBirth":"","gender":"","maritalStatus":"","fatherName":"","idNumber":"","address":"","city":"","country":"",
      "mobile":"","phone":"","emailAddress":"","userId":0
    },
    dateofJoining:moment().format("DD MMMM YYYY"),
    bloodGroup: "B+",
    designation: {
      id: null,
title: ''
    }
  })

  const getPayrollInfo = useCallback(() => {
    fetchAuthenticatedData('/api/users/' + staffId)
  .then(res => {
      console.log(res)
      setPayrollInfo(res)
      // this.setState({user: res.data}, () => {
      //     if(this.state.user.jobs) {
      //         this.state.user.jobs.map(job => {
      //             if(new Date(job.startDate).setHours(0) < new Date() && new Date(job.endDate).setHours(24) > new Date()) {
      //                 this.setState({currentJobTitle: job.jobTitle})
      //             }
      //         })
      //     }
      // })
  })
  .catch(err => {
      console.log(err)
  })
  }, [staffId])

  useEffect(() => {
    getPayrollInfo()
  }, [getPayrollInfo])

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },

    {
      title: "Salary For",
      dataIndex: "salaryFor",
      sorter: (a: TableData, b: TableData) =>
        a.salaryFor.length - b.salaryFor.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a: TableData, b: TableData) => a.date.length - b.date.length,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      sorter: (a: TableData, b: TableData) =>
        a.paymentMethod.length - b.paymentMethod.length,
    },
    {
      title: "Net Salary",
      dataIndex: "netSalary",
      sorter: (a: TableData, b: TableData) =>
        a.netSalary.length - b.netSalary.length,
    },
    {
      title: "",
      dataIndex: "view",
      render: (text: string) => (
        <>
          <Link to="#" className="btn btn-light add-fee">
            {text}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.view.length - b.view.length,
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              {/* Page Header */}
              <div className="col-md-12">
                <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                  <div className="my-auto mb-2">
                    <h3 className="page-title mb-1">Staff Salary Details</h3>
                    <nav>
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <Link to={routes.adminDashboard}>Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to={routes.payroll}>Payroll</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Staff Payroll Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="d-flex my-xl-auto right-content align-items-center  flex-wrap">
                    <Link
                      to={routes.editStaffPayroll + "/" + staffId}
                      className="btn btn-primary d-flex align-items-center mb-2"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit Payroll Information
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="col-xxl-3 col-lg-4 theiaStickySidebar">
                <StaffBasicInfo basicInfo={payrollInfo} />
              </div>
              <div className="col-xxl-9 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                      <li>
                        <Link to={routes.staffDetails + "/" + staffId} className="nav-link">
                          <i className="ti ti-info-square-rounded me-2" />
                          Basic Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={routes.staffPayroll + "/" + staffId}
                          className="nav-link active"
                        >
                          <i className="ti ti-file-dollar me-2" />
                          Payroll
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffLeave + "/" + staffId} className="nav-link">
                          <i className="ti ti-calendar-due me-2" />
                          Leaves
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffsAttendance + "/" + staffId} className="nav-link">
                          <i className="ti ti-calendar-due me-2" />
                          Attendance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="students-leaves-tab">
                  <div className="row">
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center justify-content-between rounded border p-3 mb-3 flex-fill">
                        <div className="ms-2">
                          <p className="mb-1">Total Net Salary</p>
                          <h5>{CURRENCY}{payrollInfo?.user_financial_info?.salaryNet?.toLocaleString()}</h5>
                        </div>
                        <span className="avatar avatar-lg bg-secondary-transparent rounded me-2 flex-shrink-0 text-secondary">
                          <i className="ti ti-user-dollar fs-24" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center justify-content-between rounded border p-3 mb-3 flex-fill">
                        <div className="ms-2">
                          <p className="mb-1">Total Gross Salary</p>
                          <h5>{CURRENCY}{payrollInfo?.user_financial_info?.salaryGross?.toLocaleString()}</h5>
                        </div>
                        <span className="avatar avatar-lg bg-warning-transparent rounded me-2 flex-shrink-0 text-warning">
                          <i className="ti ti-map-dollar fs-24" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center justify-content-between rounded border p-3 mb-3 flex-fill">
                        <div className="ms-2">
                          <p className="mb-1">Basic Salary</p>
                          <h5>{CURRENCY}{payrollInfo?.user_financial_info?.salaryBasic?.toLocaleString()}</h5>
                        </div>
                        <span className="avatar avatar-lg bg-success-transparent rounded me-2 flex-shrink-0 text-success">
                          <i className="ti ti-arrow-big-up-lines fs-24" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center justify-content-between rounded border p-3 mb-3 flex-fill">
                        <div className="ms-2">
                          <p className="mb-1">Total Deduction</p>
                          <h5>{CURRENCY}{payrollInfo?.user_financial_info?.deductionTotal?.toLocaleString()}</h5>
                        </div>
                        <span className="avatar avatar-lg bg-danger-transparent rounded me-2 flex-shrink-0 text-danger">
                          <i className="ti ti-arrow-big-down-lines fs-24" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">Payroll</h4>
                      <Link
                        to="#"
                        className="btn btn-primary d-inline-flex align-items-center mb-3"
                      >
                        <i className="ti ti-calendar-event me-2" />
                        Apply Payroll
                      </Link>
                    </div>
                    {/* {JSON.stringify(payrollInfo)}vv */}
                    <div className="card-body p-0 py-3">
                      {/* Payroll List */}
                      <Table
                        columns={columns}
                        dataSource={[]}
                        Selection={true}
                      />
                      {/* /Payroll List */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default StaffPayRoll;
