import React from 'react'
import ImageWithBasePath from '../../../../core/common/imageWithBasePath'
import { StaffPayrollType } from '../staff-payroll.tsx'

export type UserPersonalInfo = {
    "id":number;"dateOfBirth":string;"gender":string;"maritalStatus":string;
    "fatherName":string;"idNumber":string;"address":string;"city":string;"country":string;"mobile":string;
    "phone":string;"emailAddress":string;"userId":number
  }

  type StaffBasicInfoProps = {
    basicInfo: StaffPayrollType|undefined
  }

function StaffBasicInfo(props:StaffBasicInfoProps) {
  return (
    <div className="stickybar pb-4">
                <div className="card border-white">
                {/* {JSON.stringify(props.basicInfo)} */}
                  <div className="card-header">
                    <div className="d-flex align-items-center  row-gap-3">
                      <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-27.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <span className="badge badge-soft-success d-inline-flex align-items-center mb-1">
                          <i className="ti ti-circle-filled fs-5 me-1" />
                          Active
                        </span>
                        <h5 className="mb-1" > {props?.basicInfo?.fullName}</h5>
                        <p className="text-primary m-0">AD1256589</p>
                        <p className="p-0">Joined On : {props?.basicInfo?.dateofJoining}</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="mb-3">Basic Information</h5>
                    <dl className="row mb-0">
                      <dt className="col-6 fw-medium text-dark mb-3">
                        Staff ID:
                      </dt>
                      <dd className="col-6  mb-3">{props.basicInfo?.user_personal_info?.id}</dd>
                      <dt className="col-6 fw-medium text-dark mb-3">Gender</dt>
                      <dd className="col-6  mb-3">{props.basicInfo?.user_personal_info?.gender}</dd>
                      <dt className="col-6 fw-medium text-dark mb-3">
                        Designation:
                      </dt>
                      <dd className="col-6  mb-3">{props.basicInfo?.designation?.title}</dd>
                      <dt className="col-6 fw-medium text-dark mb-3">
                        Department:
                      </dt>
                      <dd className="col-6  mb-3">{props.basicInfo?.department?.departmentName}</dd>
                      <dt className="col-6 fw-medium text-dark mb-3">
                        Date Of Birth:
                      </dt>
                      <dd className="col-6  mb-3">{props.basicInfo?.user_personal_info?.dateOfBirth}</dd>
                      <dt className="col-6 fw-medium text-dark mb-3">
                        Blood Group:
                      </dt>
                      <dd className="col-6  mb-3">{props?.basicInfo?.bloodGroup}</dd>
                      {/* <dt className="col-6 fw-medium text-dark mb-3">
                        Blood Group
                      </dt>
                      <dd className="col-6  mb-3">O+</dd> */}
                      {/* <dt className="col-6 fw-medium text-dark mb-3">
                        Mother tongue
                      </dt> */}
                      {/* <dd className="col-6  mb-3">English</dd>
                      <dt className="col-6 fw-medium text-dark mb-0">
                        Language
                      </dt>
                      <dd className="col-6 text-dark mb-0">
                        <span className="badge badge-light text-dark me-2">
                          English
                        </span>
                        <span className="badge badge-light text-dark">
                          Spanish
                        </span>
                      </dd> */}
                    </dl>
                  </div>
                </div>
                <div className="card border-white mb-0">
                  <div className="card-body">
                    <h5 className="mb-3">Primary Contact Info</h5>
                    <div className="d-flex align-items-center mb-3">
                      <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                        <i className="ti ti-phone" />
                      </span>
                      <div>
                        <span className="mb-1 fw-medium text-dark ">
                          Phone Number
                        </span>
                        <p>{props.basicInfo?.user_personal_info?.phone}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                        <i className="ti ti-mail" />
                      </span>
                      <div>
                        <span className="mb-1 fw-medium text-dark ">
                          Email Address
                        </span>
                        <p>{props.basicInfo?.user_personal_info?.emailAddress}</p>
                      </div>
                  {/* {JSON.stringify(props.basicInfo)} */}

                    </div>
                  </div>
                </div>
                </div>
  )
}

export default StaffBasicInfo