import React from 'react'
import { Link } from 'react-router-dom'
import { CURRENCY } from '../../../../core/data/constants'
import { EmployeeType } from '.'

 type PayslipProps = {
  employee: EmployeeType|undefined
}

function PaySlip(props:PayslipProps) {
  return (
    <div className="modal fade" id="view_payslip">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Employee Payslip (DRAFT)</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form >
                <div className="modal-body">
                <div className="container p-4 ">
      {/* {JSON.stringify(props.employee)} */}
      <div className="row">
        {/* Employee Information */}
        <div className="col-md-6">
          <h5>Employee Information</h5>
          <p><strong>Name:</strong> {props.employee?.user.fullName}</p>
          <p><strong>Employee ID:</strong> {props.employee?.id}</p>
          <p><strong>Department:</strong> {props.employee?.user?.department?.departmentName}</p>
          <p><strong>Designation:</strong> {props.employee?.designation}</p>
        </div>

        {/* Payslip Information */}
        <div className="col-md-6">
          <h5>Pay Details</h5>
          <p><strong>Basic Salary:</strong> {CURRENCY}{props.employee?.salaryBasic?.toLocaleString()}</p>
          <p><strong>Allowances:</strong> {CURRENCY}{props.employee?.allowanceTotal?.toLocaleString()}</p>
          <p><strong>Deductions:</strong> {CURRENCY}{props.employee?.deductionTotal?.toLocaleString()}</p>
          <p><strong>Net Salary:</strong> {CURRENCY}{props.employee?.salaryNet?.toLocaleString()}</p>
        </div>
      </div>

      {/* Pay Period and Date */}
      <div className="row mt-4">
        <div className="col-md-6">
          <p><strong>Pay Period:</strong> {'props.employee?.payPeriod'}</p>
        </div>
        <div className="col-md-6 text-md-right">
          <p><strong>Date Issued:</strong> {'props.employee?.dateIssued'}</p>
        </div>
      </div>

      {/* Footer with Signature */}
      <div className="row mt-5">
        <div className="col-md-6">
          <p><strong>Authorized By:</strong></p>
          {/* <div className="border-bottom mb-2"></div> */}
        </div>
        <div className="col-md-6 text-md-right">
          <p><strong>Signature:</strong></p>
          {/* <div className="border-bottom mb-2"></div> */}
        </div>
      </div>
    </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                
                </div>
              </form>
            </div>
          </div>
        </div>
  )
}

export default PaySlip