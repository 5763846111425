import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CURRENCY } from '../../../../core/data/constants'
import { EmployeeType } from '.'
import { useSelector } from 'react-redux'
import { fetchAuthenticatedData } from '../../../../redux/api'
import { StaffPayrollType } from '../../staff-list/staff-payroll.tsx'

function EmployeeSalaryDetails() {
  const user = useSelector((state:any) => state.auth.user)
  const [employee, setEmployee] = useState<StaffPayrollType>()

  const getPayrollInfo = useCallback(() => {
    fetchAuthenticatedData('/api/users/' + user.id)
  .then(res => {
      console.log(res)
      setEmployee(res)
  })
  .catch(err => {
      console.log(err)
  })
  }, [user.id])

  useEffect(() => {
    getPayrollInfo()
  }, [getPayrollInfo])

  const staffAllowances = employee?.staff_payroll_items?.filter((item:any) => item.payroll_item.itemType === "Allowance")
  const staffDeductions = employee?.staff_payroll_items?.filter((item:any) => item.payroll_item.itemType === "Deduction")

  return (
    <> {/* Page Wrapper */}
    <div className="page-wrapper">
      <div className="content">
    <div className="card">
          <div className="card-dialog card-lg">
            <div className="card-content">
              <div className="card-header">
                <h4 className="card-title">My Salary Details</h4>
               
              </div>
              <form >
                <div className="card-body">
                <div className="container p-4 ">
      {/* {JSON.stringify(employee)} */}
      <div className="row">
        {/* Employee Information */}
        <div className="col-md-6">
          <h5>Employee Information</h5>
          <p><strong>Name:</strong> {employee?.fullName}</p>
          <p><strong>Employee ID:</strong> {employee?.id}</p>
          <p><strong>Department:</strong> {employee?.department?.departmentName}</p>
          <p><strong>Designation:</strong> {'employee?.designation'}</p>
        </div>

        {/* Payslip Information */}
        <div className="col-md-6">
          <h5>Pay Details</h5>
          <p><strong>Basic Salary:</strong> {CURRENCY}{employee?.user_financial_info?.salaryBasic?.toLocaleString()}</p>
          <p><strong>Allowances:</strong> {CURRENCY}{employee?.user_financial_info?.allowanceTotal?.toLocaleString()}</p>
          <p><strong>Deductions:</strong> {CURRENCY}{employee?.user_financial_info?.deductionTotal?.toLocaleString()}</p>
          <p><strong>Net Salary:</strong> {CURRENCY}{employee?.user_financial_info?.salaryNet?.toLocaleString()}</p>
        </div>
      </div>

      {/* Pay Period and Date */}
      <div className="row mt-4">
        <div className="col-md-6">
          <p><strong>Pay Period:</strong> {'NOT SET'}</p>
        </div>
        {/* <div className="col-md-6 text-md-right">
          <p><strong>Date Issued:</strong> {'employee?.dateIssued'}</p>
        </div> */}
      </div>

<div className='row mt-4'>
  <div className='col-md-6'>
    <h5>Allowances Breakdown</h5>

    <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Allowance Type</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffAllowances?.map((item:any) => 
                          <tr>
                            <td>{item.payroll_item.itemName}</td>
                            <td className="text-end">{item?.amount?.toLocaleString()||""}</td>
                            </tr>)}
                        </tbody>
                      </table>
  </div>
  <div className='col-md-6'>
    <h5>Deductions Breakdown</h5>

    <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Deduction Type</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffDeductions?.map((item:any) => 
                          <tr>
                            <td>{item.payroll_item.itemName}</td>
                            <td className="text-end">{item?.amount?.toLocaleString()||""}</td>
                            </tr>)}
                        </tbody>
                      </table>
  </div>
</div>
     
    </div>
                </div>
          
              </form>
            </div>
          </div>
        </div>
        </div>
        </div>
        </>
  )
}

export default EmployeeSalaryDetails