// export const API_URL = 'http://localhost:49490'
export const API_URL = 'https://api.oumchealth.org/hrsuit'



export const AUTH_KEY = "@@token"

export default function authHeader() {
    const token = localStorage.getItem(AUTH_KEY);
    if (token) {
      return { Authorization: /*'Bearer ' + */ token };
    } else {
      return {};
    }
  }

export const fetchData = async (endpoint) => {
    try {
        const resp = await fetch(`${API_URL}${endpoint}`);
        return resp.json()
    } catch (error) {
        return error
    }
}

export const postData = async (endpoint, data) => {
    try {
        const resp = await fetch(`${API_URL}${endpoint}`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': "application/json",
            }
        })

        return resp.json()
    } catch (error) {
        return error
    }
}

export const fetchAuthenticatedData = async (endpoint) => {
    try {
        // console.log(authHeader())
        const token = localStorage.getItem(AUTH_KEY);
        const resp = await fetch(`${API_URL}${endpoint}`,{
            method: "GET",
            headers: {
                'Content-Type': "application/json",
                'Authorization': `Bearer ${token}`
                // ...authHeader
            }
        });
        return resp.json()
    } catch (error) {
        return error
    }
}

export const postAuthenticatedData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem(AUTH_KEY);
        const resp = await fetch(`${API_URL}${endpoint}`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': "application/json",
               'Authorization': `Bearer ${token}`
            }
        })

        return resp.json()
    } catch (error) {   
        return error
    }
}

export const putAuthenticatedData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem(AUTH_KEY);
        const resp = await fetch(`${API_URL}${endpoint}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': "application/json",
               'Authorization': `Bearer ${token}`
            }
        })

        return resp.json()
    } catch (error) {   
        return error
    }
}

export const deleteAuthenticatedData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem(AUTH_KEY);
        const resp = await fetch(`${API_URL}${endpoint}`, {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': "application/json",
               'Authorization': `Bearer ${token}`
            }
        })

        return resp.json()
    } catch (error) {   
        return error
    }
}