export const bookIssueList = [
    {
      key: "1",
      id: "IB853629",
      dateofIssue: "20 Apr 2024",
      dueDate: "19 May 2024",
      issueTo: "Janet",
      class: "III, A",
      booksIssued: "1",
      bookReturned: "0",
      img: "assets/img/students/student-01.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "2",
      id: "IB853628",
      dateofIssue: "24 Apr 2024",
      dueDate: "20 May 2024",
      issueTo: "Joann",
      class: "IV, B",
      booksIssued: "5",
      bookReturned: "3",
      img: "assets/img/students/student-02.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "3",
      id: "IB853627",
      dateofIssue: "02 May 2024",
      dueDate: "01 Jun 2024",
      issueTo: "Kathleen",
      "class ": "III, A",
      booksIssued: "4",
      bookReturned: "2",
      img: "assets/img/students/student-03.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "4",
      id: "IB853626",
      dateofIssue: "16 May 2024",
      dueDate: "15 Jun 2024",
      issueTo: "Gifford",
      class: "I, B",
      booksIssued: "3",
      bookReturned: "2",
      img: "assets/img/students/student-04.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "5",
      id: "IB853625",
      dateofIssue: "22 May 2024",
      dueDate: "20 Jun 2024",
      issueTo: "Lisa",
      class: "II, B",
      booksIssued: "6",
      bookReturned: "4",
      img: "assets/img/students/student-05.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "6",
      id: "IB853624",
      dateofIssue: "10 Jun 2024",
      dueDate: "08 Jul 2024",
      issueTo: "Ralph",
      class: "III, B",
      booksIssued: "4",
      bookReturned: "2",
      img: "assets/img/students/student-06.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "7",
      id: "IB853623",
      dateofIssue: "15 Jun 2024",
      dueDate: "14 Jul 2024",
      issueTo: "Julie",
      class: "V, A",
      booksIssued: "5",
      bookReturned: "3",
      img: "assets/img/students/student-07.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "8",
      id: "IB853622",
      dateofIssue: "26 Jun 2024",
      dueDate: "25 Jul 2024",
      issueTo: "Ryan",
      class: "VI, A",
      booksIssued: "3",
      bookReturned: "1",
      img: "assets/img/students/student-08.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "9",
      id: "IB853621",
      dateofIssue: "06 Jul 2024",
      dueDate: "05 Aug 2024",
      issueTo: "Susan",
      class: "VIII, B",
      booksIssued: "6",
      bookReturned: "4",
      img: "assets/img/students/student-09.jpg",
      issueRemarks: "Book Issued",
      
    },
    {
      key: "10",
      id: "IB853620",
      dateofIssue: "18 Jul 2024",
      dueDate: "16 Aug 2024",
      issueTo: "Richard",
      class: "VII, B",
      booksIssued: "2",
      bookReturned: "1",
      img: "assets/img/students/student-10.jpg",
      issueRemarks: "Book Issued",
      
    },
  ];