import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { expense_data } from "../../core/data/json/expense_data";
import Table from "../../core/common/dataTable/index";
import PredefinedDateRanges from "../../core/common/datePicker";
import CommonSelect from "../../core/common/commonSelect";
import {
  category2,
  expenseName,
  invoiceNumber,
  paymentMethod,
} from "../../core/common/selectoption/selectoption";
import { TableData } from "../../core/data/interface";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";
import { AddStaffForm } from "../hrm/staff-list/add-staff";
import { fetchAuthenticatedData, postAuthenticatedData } from "../../redux/api";
import { toast } from "react-toastify";
import moment from "moment";

type ExpenseFormType = {
  selectedDepartment: string|null,
  selectedExpenseCategory: string|null
  itemName: string,
  purchasedFrom: string,
  purchaseDate: string,
  amountSpent: number,
  invoiceNo: string,
}

const Expense = () => {
  const data = expense_data;
  const routes = all_routes;
  const [form, setForm] = useState<ExpenseFormType>({
    selectedDepartment: null,
    selectedExpenseCategory: null,
    itemName: "",
    purchasedFrom: "",
    purchaseDate: "",
    amountSpent: 0,
    invoiceNo: ""
  })
  const [expenseReport, setExpenseReport] = useState([])
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [expenseCategoryList, setExpenseCategoryList] = useState([])

  const getExpenseCategoryList = () => {
    // event.preventDefault()
  
    fetchAuthenticatedData('/api/expensesCategory')
    .then(res => {
        // console.log(res)
        if(res && res.length) {
          setExpenseCategoryList(res)
        }
    }).catch(err => {
      console.log(err)
    })
  }
  
  useEffect(() => {
    getExpenseCategoryList()
  }, [])

  const getDepartmentlist = () => {
    fetchAuthenticatedData('/api/departments')
    .then(res => {
      setDepartmentList(res)
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getDepartmentlist()
  }, [])
  
  const handleChange = (e:any) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = (event:any) => {
    event.preventDefault()
    setLoading(true)

    let newExpense = {
        expenseItemName: form.itemName,
        expenseItemStore: form.purchasedFrom,
        date: new Date(form.purchaseDate).setHours(15),
        amount: form.amountSpent,
        departmentId: form.selectedDepartment,
        expenseCategoryId: form.selectedExpenseCategory,
        invoiceNo: form.invoiceNo
    }

    postAuthenticatedData('/api/expenses/', newExpense)
    .then(res => {
      setLoading(false)
      getExpenseReport()
        if(res.message) {
          toast(res.message)
        } else {
          toast("Expense recorded successfully!", {type: 'success' })
        }
    })
    .catch(err => {
      setLoading(false)
      toast(err.message, { type: 'error'})
    })
}

const getExpenseReport = () => {
  // event.preventDefault()

  fetchAuthenticatedData('/api/expenses')
  .then(res => {
      console.log(res)

      // let expenses = []
      setExpenseReport(res)
      // if(res && res.length) {
      //   res.map(expense => {
      //     if(new Date(expense.date).getMonth() == new Date(this.state.selectedDate).getMonth() && new Date(expense.date).getFullYear() == new Date(this.state.selectedDate).getFullYear()) {
      //       expenses.push(expense)
      //     }
      // })
      // }
      // this.setState({expenses: expenses})
  }).catch(err => {
    console.log(err)
  })
}

useEffect(() => {
  getExpenseReport()
}, [])

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: any) => (
        <Link to="#" className="link-primary">
          {text}
        </Link>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Expense Name",
      dataIndex: "expenseItemName",
      // sorter: (a: TableData, b: TableData) => a.expenseItemName.length - b.expenseItemName.length,
    },
    {
      title: "Department",
      dataIndex: "description",
      render: (_: any, record: any) => (
        <div>{record?.department?.departmentName}</div>
      )
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (_: any, record: any) => (
        <div>{record?.expense_category?.expenseCategoryName}</div>
      )
      // sorter: (a: TableData, b: TableData) => a.category.length - b.category.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      // sorter: (a: TableData, b: TableData) => a.date.length - b.date.length,
      render: (_: any, record: any) => (
        <div>{moment(record?.date).format("DD MMMM YYYY")}</div>
      )
    },
    {
      title: "Amount",
      dataIndex: "amount",
      // sorter: (a: TableData, b: TableData) => a.amount.length - b.amount.length,
      render: (_: any, record: any) => (
        <div className="text-end">{record?.amount.toLocaleString()}</div>
      )
    },
    // {
    //   title: "Invoice No",
    //   dataIndex: "invoiceNo",
    //   sorter: (a: TableData, b: TableData) => a.invoiceNo.length - b.invoiceNo.length,
    //   render: (text: any) => (
    //     <Link to="#" className="link-primary">
    //       {text}
    //     </Link>
    //   ),
    // },
    // {
    //   title: "Payment Method",
    //   dataIndex: "paymentMethod",
    //   sorter: (a: TableData, b: TableData) => a.paymentMethod.length - b.paymentMethod.length,
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (_: any, record: any) => (
    //     <>
    //       <div className="d-flex align-items-center">
    //         <div className="dropdown">
    //           <Link
    //             to="#"
    //             className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="ti ti-dots-vertical fs-14" />
    //           </Link>
    //           <ul className="dropdown-menu dropdown-menu-right p-3">
    //             <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#edit_expenses"
    //               >
    //                 <i className="ti ti-edit-circle me-2" />
    //                 Edit
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#delete-modal"
    //               >
    //                 <i className="ti ti-trash-x me-2" />
    //                 Delete
    //               </Link>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  return (
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Expense</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Finance &amp; Accounts</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Expense
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_expenses"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Expense
                </Link>
              </div>
            </div>
          </div>
          {/* Page Header */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Expense List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div className="dropdown-menu drop-width">
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 pb-0 border-bottom">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Expense Name</label>
                              <CommonSelect
                                className="select"
                                options={expenseName}
                                defaultValue={expenseName[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Category</label>
                              <CommonSelect
                                className="select"
                                options={category2}
                                defaultValue={category2[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Invoice Number
                              </label>
                              <CommonSelect
                                className="select"
                                options={invoiceNumber}
                                defaultValue={invoiceNumber[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Expenses List */}
              <Table dataSource={expenseReport} columns={columns} Selection={true} />
              {/* /Expenses List */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Expenses */}
      <div className="modal fade" id="add_expenses">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Expense</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {/* {JSON.stringify(form)} */}
                    <div className="mb-3">
                      <label className="form-label">Item Name</label>
                      <input type="text" className="form-control" name="itemName" value={form.itemName} onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Category</label>
                      <CommonSelect
                        className="select"
                        // options={category2}
                        // defaultValue={category2[0]}
                        options={expenseCategoryList.map((d:any) => ({label: d.expenseCategoryName, value: d.id }))}
                        value={{label: form?.selectedExpenseCategory||"", value: form.selectedExpenseCategory || ""}}
                        handleChange={(option:any) => setForm(prev => ({...prev, selectedExpenseCategory: option.value }))}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Purchased from:</label>
                      <input type="text" className="form-control"
                      name="purchasedFrom" value={form.purchasedFrom}
                      onChange={handleChange}
                       />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date</label>
                      <input type="date" className="form-control" 
                      name="purchaseDate" value={form.purchaseDate}
                      onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Amount</label>
                      <input type="text" className="form-control" 
                      name="amountSpent" value={form.amountSpent}
                      onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                    <label className="fw-bold">Select Department</label>
                    <CommonSelect
                        className="select"
                        options={departmentList.map((d:any) => ({label: d.departmentName, value: d.id }))}
                        value={{label: form?.selectedDepartment||"", value: form.selectedDepartment || ""}}
                        handleChange={(option:any) => setForm(prev => ({...prev, selectedDepartment: option.value }))}
                    />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Invoice No</label>
                      <input type="text" className="form-control" 
                      name="invoiceNo" value={form.invoiceNo}
                      onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Payment Method</label>
                      <CommonSelect
                        className="select"
                        options={paymentMethod}
                        defaultValue={paymentMethod[0]}
                      />
                    </div>
                    {/* <div className="mb-0">
                      <label className="form-label">Description</label>
                      <textarea
                        rows={4}
                        className="form-control"
                        defaultValue={""}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary"
                disabled={loading} data-bs-dismiss="modal"
                onClick={handleSubmit}>
                  {loading ? "Loading" : "Add Expense"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Add Expenses */}
      {/* Edit Expenses */}
      <div className="modal fade" id="edit_expenses">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Expense</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Item Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Expense Name"
                        // defaultValue="Monthly Electricity"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Category</label>
                      <CommonSelect
                        className="select"
                        options={category2}
                        defaultValue={category2[0]}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Date"
                        defaultValue="25 Apr 2024"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount"
                        defaultValue="$1000"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Invoice No</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Invoice No"
                        defaultValue="INV681537"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Payment Method</label>
                      <CommonSelect
                        className="select"
                        options={paymentMethod}
                        defaultValue={paymentMethod[0]}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Description</label>
                      <textarea
                        rows={4}
                        className="form-control"
                        placeholder="text"
                        defaultValue={"Electricity of April month"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Edit Expenses */}
      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>
                  You want to delete all the marked items, this cant be undone
                  once you delete.
                </p>
                <div className="d-flex justify-content-center">
                  <Link
                    to="#"
                    className="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-danger">
                    Yes, Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Delete Modal */}
    </div>
  );
};

export default Expense;
