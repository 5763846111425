import React, { useEffect, useState } from "react";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  bloodGroup,
  Contract,
  gender,
  Hostel,
  Marital,
  PickupPoint,
  roomno,
  route,
  Shift,
  staffDepartment,
  staffrole,
  VehicleNumber,


} from "../../../../core/common/selectoption/selectoption";
import { DatePicker } from "antd";
import type { DatePickerProps } from 'antd';
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { TagsInput } from "react-tag-input-component";
import { fetchAuthenticatedData, postAuthenticatedData } from "../../../../redux/api";
import moment from "moment";
import { toast } from "react-toastify";

export type UserFinancialInfo = {
  
    id: string;
    employmentType: string;
    salaryBasic: string;
    salaryGross: string;
    salaryNet: string;
    allowanceHouseRent: string;
    allowanceMedical: string;
    allowanceSpecial: string;
    allowanceFuel: string;
    allowancePhoneBill: string;
    allowanceOther: string;
    allowanceTotal: string;
    deductionProvidentFund: string;
    deductionTax: string;
    deductionOther: string;
    deductionTotal: string;
    bankName: string;
    accountName: string;
    accountNumber: string;
    iban:string;
    userId: Number;

}

export type UserPersonalInfo = {
address: string;
city: string;
country: string;
dateOfBirth: string;
emailAddress: string;
fatherName: string;
gender: string;
id:number;
idNumber: string;
maritalStatus: string;
mobile: null;
phone:string;
userId:number;

}

export type AddStaffForm = {
  Id:string,
  fullName?:string;
  fistname: string;
      lastname: string;
      dateOfBirth: string;
      gender: string;
      maritalStatus: string;
      fathername: string;
      idNumber: string;
      bankName: string;
      iBan: string;
      address: string;
      country: string;
      city: string;
      mobile: string|null,
      phone: string,
      email: string;
      username: string;
      password: string;
      role: string;
      department: Department;
      departmentId: string|null,
      startDate: string;
      endDate: string;
      departments:string[];
      jobTitle: string|null;
      qualification:string;
      workExperience:string;
      note:string;
      paddress:string;
      basicsalary:string;
      medicalLeaves:string;
      worklocation:string;
      contractType:string;
     bloodGroup:string;
     maritalstatus:string;
     dateofJoining:string;
     EPFNo:string;
    casualLeaves:string;
    maternityLeaves:string;
    sickLeaves:string;
    accountName:string;
    accountNumber:string;
    branchName:string;
    uploadresume:string;
    uploadjoiningLetter:string;
    newPassword:string;
    confirmPassword:string;
    ePFNo:string;
    designationId: string,
    designationText: string,
    designation: {
      id: string|null,
      title: ''
    },
    workShift:string;
    emailAddress?:string
    user_personal_info?:UserPersonalInfo,
    user_financial_info?:UserFinancialInfo,
    
    

}

type Department = {
  id: string|null;
  departmentName: string;
}

const AddStaff = () => {
  const [owner, setOwner] = useState<string[]>([]);
  const [addstaff, setAddstaff] = useState([])
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)

  const [departmentList, setDepartmentList] = useState<Department[]>([])
  const [designationList, setDesignationList] = useState([])
  
    const getDesignationlist = () => {
      fetchAuthenticatedData('/api/designations')
      .then(res => {
        setDesignationList(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  
    useEffect(() => {
      getDesignationlist()
    }, [])

  const getDepartmentlist = () => {
    fetchAuthenticatedData('/api/departments')
    .then(res => {
      if (res && res.length) {
       setDepartmentList(res) 
      }
      
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getDepartmentlist()
  }, [])

  const routes = all_routes;
  const [form, setForm] = useState<AddStaffForm>({
    Id:'',
    fistname: "",
    lastname: "",
    dateOfBirth: "",
    gender: "",
    maritalStatus: "",
    fathername: "",
    idNumber: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    iBan: "",
    address: "",
    country: "",
    city: "",
    mobile: null,
    phone: "",
    email: "",
    username: "",
    password: "",
    role: "",
    department: {
      id: null,
      departmentName: ""
    },
    designation: {
id:null,
title: '',
    },
    departmentId: null,
    startDate: "",
    endDate: "",
    departments: [],
    jobTitle: null,
    qualification:'',
    workExperience:'',
    note:'',
    paddress:'',
    basicsalary:'',
    medicalLeaves:'',
    worklocation:'',
    contractType:'',
    designationId: '',
    designationText: '',
    bloodGroup:'',
    maritalstatus:'',
    dateofJoining:'',
    EPFNo:'',
    casualLeaves:'',
    maternityLeaves:'',
    sickLeaves:'',
    branchName:'',
    uploadresume:'',
    uploadjoiningLetter:'',
    newPassword:'',
    confirmPassword:'',
    ePFNo:'',
    workShift:''
  })

  const handleSelectchange =(key:string, value:string|string[]) => {
    setForm(prev => ({...prev, [key]: value }))
  }

  const handleChange = (e: any) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  // const handleDatechange: DatePickerProps['onChange'] = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  // const handleSubmit = (e:any) => {
  //   e.preventDefault()
  //   postAuthenticatedData("/api/users", form)
  //   .then((res) => {
  //     alert('success')
  //     setForm(prev => ({...prev,departmentName:''}))
  //     // localStorage.setItem(AUTH_KEY, res.token)
  //     // navigate(routes.adminDashboard)
  //     // this.setState({done: true})
  //   })
  //   .catch((err) => {
  //     console.log(err.response);
  //     alert(err)
  //     // this.setState({
  //     //   hasError: true,
  //     //   errorMessage: err.response.data.message
  //     // });
  //   });

  // }
 const handleSubmit = (e:any) => {
    // this.setState({ hasError: false, errorMsg: "", completed: false });
setSubmitting(true)
    let user = {
      username: form.username,
      password: form.password, //1234,
      fullname: form.fistname + " " + form.lastname,
      role: form.role,
      designationId: form.designationId,
      departmentId: form.departmentId,
      active: 1,
      mobile: form.mobile,
      phone: form.phone,
      emailAddress: form.email,
      dateOfBirth: form.dateOfBirth,
      gender: form.gender,
      maritalStatus: form.maritalStatus,
      // appAccess: form.appAccess,
      address: form.address,
    };

    e.preventDefault();
    postAuthenticatedData("/api/users",user)
      .then((res) => {
        
        let userId = res.id;

        let userPersonalInfo = {
          dateOfBirth: moment(form.dateOfBirth, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss"),
          gender: form.gender,
          maritalStatus: form.maritalStatus,
          fatherName: form.fathername,
          idNumber: form.idNumber,
          address: form.address,
          city: form.city,
          country: form.country,
          mobile: form.mobile,
          phone: form.phone,
          emailAddress: form.email,
          userId: userId,
        };
          postAuthenticatedData( "/api/personalInformations",userPersonalInfo) 
          .then((res) => {
            let userFinancialInfo = {
              bankName: form.bankName,
              accountName: form.accountName,
              accountNumber: form.accountNumber,
              iban: form.iBan,
              userId: userId,
            };

            postAuthenticatedData("/api/financialInformations",userFinancialInfo)
              .then((res) => {
                let job = {
                  jobTitle: form.jobTitle,
                  startDate: form.startDate,
                  endDate: form.endDate,
                  userId: userId,
                };
                // postAuthenticatedData( "/api/jobs/",job)
                //   .then((res) => {
                    if(res.message){
                      toast(res.message)
                    } else {
                      toast("Employee added successfully")
                    }
setSubmitting(false)
                    navigate(routes.staff)
                    // this.setState({ completed: true });
                    // window.scrollTo(0, 0);
                  // })
                  // .catch((err) => {
                  //   console.log(err)
                  // //   this.setState({
                  // //     hasError: true,
                  // //     errMsg: err.response.data.message,
                  // //   });
                  // //   window.scrollTo(0, 0);
                  // });
              })
              .catch((err) => {
                console.log(err)
                setSubmitting(false)
                if(err &&err.message) {
                  toast(err.message)
                } else {
                  toast("An error occurred, please try again later!")
                }
              //   this.setState({
              //     hasError: true,
              //     errMsg: err.response.data.message,
              //   });
              //   window.scrollTo(0, 0);
              });
          })
          .catch((err) => {
            console.log(err)
            setSubmitting(false)
            if(err &&err.message) {
              toast(err.message)
            } else {
              toast("An error occurred, please try again later!")
            }
          //   this.setState({
          //     hasError: true,
          //     errMsg: err.response.data.message,
          //   });
          //   window.scrollTo(0, 0);
          });
      })
      .catch((err) => {
        // this.setState({ hasError: true, errMsg: err.response.data.message });
        // window.scrollTo(0, 0);
        console.log(err)
        setSubmitting(false)
        if(err &&err.message) {
          toast(err.message)
        } else {
          toast("An error occurred, please try again later!")
        }
      });
  };

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="mb-1">Add Staff</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">HRM</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Staff
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form >
               
                  {/* Personal Information */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">Personal Information</h4>
                      </div>
                    </div>
                    {/* {JSON.stringify(form)} */}
                    <div className="card-body pb-1">
                      <div className="add-section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                              <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                                <i className="ti ti-photo-plus fs-16" />
                              </div>
                              <div className="profile-upload">
                                <div className="profile-uploader d-flex align-items-center">
                                  <div className="drag-upload-btn mb-3">
                                    Upload
                                    <input
                                      type="file"
                                      className="form-control image-sign"
                                      multiple
                                    />
                                  </div>
                                  <Link to="#" className="btn btn-primary mb-3">
                                    Remove
                                  </Link>
                                </div>
                                <p className="fs-12">
                                  Upload image size 4MB, Format JPG, PNG, SVG
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row-cols-xxl-5 row-cols-md-6">
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">First Name <span className="text-danger">*</span></label>
                              <input type="text"
                               className="form-control " id="validationServer01"  
                               name="fistname"
                               value={form.fistname}
                               onChange={handleChange}
                               required
                               />
                                
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Last Name <span className="text-danger">*</span></label>
                              <input type="text"
                               className="form-control"
                               name="lastname"
                               value={form.lastname}
                               onChange={handleChange}
                               required/>
                            </div>
                          </div>
                        
                         
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Designation <span className="text-danger">*</span></label>
                              <CommonSelect
                                className="select"
                                options={designationList.map((d:any) => ({label:d.title,value: d.id}))}
                                value={{label:form.designationText, value:form.designationId}}
                                handleChange={(value:any) => {
                                  handleSelectchange("designationId", value.value)
                                  handleSelectchange("designationText", value.label)
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender <span className="text-danger">*</span></label>
                              <CommonSelect
                                className="select"
                                options={gender}
                                value={{label:form.gender, value:form.gender}}
                                handleChange={(value:any) => handleSelectchange("gender", value.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Primary Contact Number <span className="text-danger">*</span>
                              </label>
                              <input type="text"
                               className="form-control" 
                               name="phone"
                               value={form.phone}
                               onChange={handleChange}
                               required/>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Email Address <span className="text-danger">*</span>
                              </label>
                              <input type="email"
                               className="form-control"
                               name="email"
                               value={form.email}
                               onChange={handleChange}
                               required />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Blood Group <span className="text-danger">*</span></label>
                              <CommonSelect
                                className="select"
                                options={bloodGroup}
                                // name="email"
                              //  value={form.email}
                              value={{label:form.bloodGroup, value:form.bloodGroup}}
                              handleChange={(value:any) => handleSelectchange("bloodGroup", value.value)}
                              required
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Marital Status <span className="text-danger">*</span>
                              </label>
                              <CommonSelect
                                className="select"
                                options={Marital}
                                value={{label:form.maritalStatus, value:form.maritalStatus}}
                                handleChange={(value:any) => handleSelectchange("maritalStatus", value.value)}
                                required
                              />
                            </div>
                          </div>
                          {/* <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Father’s Name
                              </label>
                              <input type="text"
                               className="form-control"
                               name="father's name"
                               value={form.phone} />
                            </div> */}
                          {/* </div> */}
                          {/* <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Mother’s Name
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div> */}
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Date of Birth <span className="text-danger">*</span>
                              </label>
                              <div className="input-icon position-relative">
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                   
                                  }}
                                  placeholder="Select Date"
                                  onChange={(date, dateString):DatePickerProps['onChange'] => {
                                    handleSelectchange('dateOfBirth', dateString)
                                    return;
                                   
                                  }}
                                  required
                                
                                  
                                />
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Date of Joining <span className="text-danger">*</span>
                              </label>
                              <div className="input-icon position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  placeholder="Select Date"
                                  onChange={(date, dateString):DatePickerProps['onChange'] => {
                                    handleSelectchange('dateofJoining', dateString)
                                    return;
                                  
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Language Known
                              </label>
                              <TagsInput
                            
                            value={owner}
                            onChange={setOwner}
                          />
                            </div>
                          </div> */}
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Qualification <span className="text-danger">*</span>
                              </label>
                              <input type="text"
                               className="form-control"
                               name="qualification"
                               value={form.qualification}
                               onChange={handleChange}
                               required />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Work Experience <span className="text-danger">*</span>
                              </label>
                              <input type="text" 
                              className="form-control"
                              name="workExperience"
                              value={form.workExperience}
                              onChange={handleChange}
                              required />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Note <span className="text-danger">*</span></label>
                              <input type="text" 
                              className="form-control"
                              name="note"
                              value={form.note}
                              onChange={handleChange}
                              required/>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-3  col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Address <span className="text-danger">*</span> </label>
                              <input type="text" 
                              className="form-control"
                              name="address"
                              value={form.address}
                              onChange={handleChange} 
                              required/>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-3  col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Permanent Address <span className="text-danger">*</span>
                              </label>
                              <input type="text" 
                              className="form-control"
                              name="paddress"
                              value={form.paddress}
                              onChange={handleChange} 
                              required/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Personal Information */}
                  {/* Payroll */}
                  {/* <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Payroll</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-3 col-md-6"> 
                           <div className="mb-3"> 
                            <label className="form-label">EPF No <span className="text-danger">*</span> </label>
                            <input type="text" 
                            className="form-control" 
                            name="ePFNo"
                            value={form.ePFNo}
                            onChange={handleChange}
                            required
                            /> 
                          </div> 
                        </div> 
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Basic Salary <span className="text-danger">*</span> </label>
                            <input type="text" 
                            className="form-control" 
                            name="basicsalary"
                            value={form.basicsalary}
                            onChange={handleChange}
                            required/>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Contract Type <span className="text-danger">*</span> </label>
                            <CommonSelect
                              className="select"
                              options={Contract}
                              value={{label:form.contractType, value:form.contractType}}
                              handleChange={(value:any) => handleSelectchange("contractType", value.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Shift <span className="text-danger">*</span> </label>
                            <CommonSelect
                             className="select"
                             options={Shift}
                             value={{label:form.workShift, value:form.workShift}}
                             handleChange={(value:any) => handleSelectchange("workShift", value.value)} 
                             required/>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Location <span className="text-danger">*</span> </label>
                            <input type="text"
                             className="form-control"
                             name="worklocation"
                              value={form.worklocation}
                              onChange={handleChange}
                              required />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Payroll */}
                  {/* Leaves */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-dark">Leaves</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Medical Leaves</label>
                            <input type="text" 
                            className="form-control"
                            name="medicalLeaves"
                            value={form.medicalLeaves}
                            onChange={handleChange}
                             />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Casual Leaves</label>
                            <input type="text"
                             className="form-control"
                             name="casualLeaves"
                             value={form.casualLeaves}
                             onChange={handleChange}/>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Maternity Leaves
                            </label>
                            <input type="text" 
                            className="form-control"
                            name="maternityLeaves"
                            value={form.maternityLeaves}
                            onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Sick Leaves</label>
                            <input type="text"
                             className="form-control"
                             name="sickLeaves"
                            value={form.sickLeaves}
                            onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Leaves */}
                  {/* Bank Details */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-map">Bank Account Detail</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name <span className="text-danger">*</span> </label>
                            <input type="text" 
                            className="form-control"
                            name="accountName"
                            value={form.accountName}
                            onChange={handleChange} 
                            required/>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Number <span className="text-danger">*</span> </label>
                            <input type="text" 
                            className="form-control"
                            name="accountNumber"
                            value={form.accountNumber}
                            onChange={handleChange}
                            required />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank Name <span className="text-danger">*</span> </label>
                            <input type="text" 
                            className="form-control"
                            name="bankName"
                            value={form.bankName}
                            onChange={handleChange}
                            required />
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div> *
                        <div className="col-lg-4 col-md-6">
                          {/* <div className="mb-3">
                            <label className="form-label">Branch Name</label>
                            <input type="text" 
                            className="form-control" 
                            name="branchName"
                            value={form.branchName}
                            onChange={handleChange}/>
                          </div> *
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Bank Details */}
                  {/* Transport Information */}
                  {/* <div className="card">
                    <div className="card-header bg-light d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-bus-stop fs-16" />
                        </span>
                        <h4 className="text-dark">Transport Information</h4>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Route</label>
                            <CommonSelect className="select" options={route} />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Vehicle Number</label>
                            <CommonSelect
                              className="select"
                              options={VehicleNumber}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Pickup Point</label>
                            <CommonSelect
                              className="select"
                              options={PickupPoint}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Transport Information */}
                  {/* Hostel Information */}
                  {/* <div className="card">
                    <div className="card-header bg-light d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building-fortress fs-16" />
                        </span>
                        <h4 className="text-dark">Hostel Information</h4>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Hostel</label>
                            <CommonSelect className="select" options={Hostel} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Room No</label>
                            <CommonSelect className="select" options={roomno} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Hostel Information */}
                  {/* Social Media Links */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building fs-16" />
                        </span>
                        <h4 className="text-dark">Social Media Links</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Facebook URL</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Twitter URL</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Linkediin URL</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Instagram URL</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Social Media Links */}
                  {/* Documents */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Documents</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Resume <span className="text-danger">*</span>
                              </label>
                              <p>
                                Upload image size of 4MB, Accepted Format PDF
                              </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Change
                                <input
                                  type="file"
                                  className="form-control image_sign"
                                  multiple
                                  required
                                />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Joining Letter <span className="text-danger">*</span>
                              </label>
                              <p>
                                Upload image size of 4MB, Accepted Format PDF
                              </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2">
                                <i className="ti ti-file-upload me-1" />
                                Upload Document
                                <input
                                  type="file"
                                  className="form-control image_sign"
                                  multiple
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Documents */}
                  {/* Password */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Official Status</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Username <span className="text-danger">*</span> </label>
                            <input type="input"
                             className="form-control"
                             name="username"
                            value={form.username}
                            onChange={handleChange}
                            required />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password <span className="text-danger">*</span>
                            </label>
                            <input type="password" 
                            className="form-control"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            required />
                          </div>
                           </div>
                          <div className="col-md-6">
                     
                          <div className="mb-3">
                              <label className="form-label">Department <span className="text-danger">*</span> </label>
                              <CommonSelect
                                className="select"
                                options={departmentList.map((dept:any) => ({label:dept.departmentName, value:dept.id }))}
                                value={{label:departmentList.find((r:Department)=>r.id===form.departmentId)?.departmentName||"", value:form?.departmentId||""}}
                                handleChange={(value:any) => handleSelectchange("departmentId", value.value)}
                                required
                                
                              />
                             </div>
                          </div>
                          <div className="col-md-6">
                          <div className="mb-3">
                              <label className="form-label">Role <span className="text-danger">*</span> </label>
                              <CommonSelect
                                className="select"
                                options={staffrole}
                                // name="lastname" 
                                value={{label:staffrole.find(r=>r.value===form.role)?.label||"", value:form.role}}
                                handleChange={(value:any) => handleSelectchange("role", value.value)}
                                required
                                
                               
                               

                              />
                    
                         
                          </div>
                         

                          
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Password */}
                  <div className="text-end">
                    <button type="button" className="btn btn-light me-3">
                      Cancel
                    </button>
                    <button className="btn btn-primary" disabled={submitting}
                    onClick={handleSubmit}
                    >
                      {submitting ? "Please wait..." : "Add Staff"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default AddStaff;
