export const permission = [
    {
        
        modules: "Classes",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "ClassRoutine",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Sections",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Subjects",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Sylabus",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "TimeTable",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "HomeWork",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Library",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Sports",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Transport",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    }
  ];
  