export const leave_report_data = [
  {
    key: "1",
    IdentificationNo: "AD9892434",
    studentName: "Janet",
    rollNo: "35013",
    avatar: "assets/img/students/student-01.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "2",
    IdentificationNo: "AD9892433",
    studentName: "Joann",
    rollNo: "35012",
    avatar: "assets/img/students/student-02.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "3",
    IdentificationNo: "AD9892432",
    studentName: "Kathleen",
    rollNo: "35011",
    avatar: "assets/img/students/student-03.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "4",
    IdentificationNo: "AD9892431",
    studentName: "Gifford",
    rollNo: "35010",
    avatar: "assets/img/students/student-04.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "5",
    IdentificationNo: "AD9892430",
    studentName: "Lisa",
    rollNo: "35009",
    avatar: "assets/img/students/student-05.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "6",
    IdentificationNo: "AD9892429",
    studentName: "Ralph",
    rollNo: "35008",
    avatar: "assets/img/students/student-06.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "7",
    IdentificationNo: "AD9892428",
    studentName: "Julie",
    rollNo: "35007",
    avatar: "assets/img/students/student-07.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "8",
    IdentificationNo: "AD9892427",
    studentName: "Ryan",
    rollNo: "35006",
    avatar: "assets/img/students/student-08.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "9",
    IdentificationNo: "AD9892426",
    studentName: "Susan",
    rollNo: "35004",
    avatar: "assets/img/students/student-09.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
  {
    key: "10",
    IdentificationNo: "AD9892425",
    studentName: "Richard",
    rollNo: "35003",
    avatar: "assets/img/students/student-10.jpg",
    medicalUsed: 2,
    medicalAvailable: 8,
    casualUsed: 4,
    casualAvailable: 8,
    maternityUsed: 0,
    maternityAvailable: 10,
    paternityUsed: 0,
    paternityAvailable: 10,
    specialUsed: 0,
    specialAvailable: 10,
  },
];
