import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from "react-select";
import Table from "../../../../core/common/dataTable/index";
import { activeList, departmentSelect } from '../../../../core/common/selectoption/selectoption';
import { TableData } from '../../../../core/data/interface';
import { departments } from '../../../../core/data/json/departments';
import PredefinedDateRanges from '../../../../core/common/datePicker';
import CommonSelect from '../../../../core/common/commonSelect';
import { all_routes } from '../../../router/all_routes';
import TooltipOption from '../../../../core/common/tooltipOption';
import { deleteAuthenticatedData, fetchAuthenticatedData, postAuthenticatedData, postData, putAuthenticatedData } from '../../../../redux/api';
import { toast } from 'react-toastify';

type PayrollItemsform = {
    id: string;
  itemName: string;
     itemType: string;
     defaultAmount:number;
}

const PayrollItems = () => {
  const routes = all_routes;
  const data = departments;
  const [form, setForm] = useState<PayrollItemsform>({
    id:"",
    itemName: "",
     itemType:"",
     defaultAmount:0
  })
  const [payrollItems, setPayrollItems] = useState([])

  const getPayrollItems = () => {
    fetchAuthenticatedData('/api/paymentItems/items')
    .then((res:any) => {
      setPayrollItems(res)
    })
    .catch((err:any) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getPayrollItems()
  }, [])

  const handleDeleteclick = (dept:any) =>{
    setForm((prev:any) => ({...prev, ...dept}))
  }

  const handleEditclick = (dept:any) =>{
    setForm((prev:any) => ({...prev, ...dept}))
  }

  const handleSubmitEdit =(e:any) => {
    e.preventDefault()
    putAuthenticatedData(`/api/paymentItems/items/${form.id}`, form)
    .then((res:any) => {
      toast(res.message)
      setForm(prev => ({...prev,itemName:''}))
      getPayrollItems()
      // localStorage.setItem(AUTH_KEY, res.token)
      // navigate(routes.adminDashboard)
      // this.setState({done: true})
    })
    .catch((err:any) => {
      console.log(err.response);
      toast(err.message, { type: 'error'})
      // this.setState({
      //   hasError: true,
      //   errorMessage: err.response.data.message
      // });
    });

  }

  const handleDelete =(e:any) => {
    e.preventDefault()
    deleteAuthenticatedData(`/api/paymentItems/items/${form.id}`, form)
    .then((res:any) => {
      toast(res.message)
      setForm(prev => ({...prev,itemName:''}))
      getPayrollItems()
      // localStorage.setItem(AUTH_KEY, res.token)
      // navigate(routes.adminDashboard)
      // this.setState({done: true})
    })
    .catch((err:any) => {
      console.log(err.response);
      toast(err.message, { type: 'error'})
      // this.setState({
      //   hasError: true,
      //   errorMessage: err.response.data.message
      // });
    });

  }

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  const handleChange = (e: any) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = (e:any) => {
    e.preventDefault()
    postAuthenticatedData("/api/paymentItems/items", form)
    .then((res:any) => {
      toast(res.message)
      setForm(prev => ({...prev,itemName:''}))
      getPayrollItems()
      // localStorage.setItem(AUTH_KEY, res.token)
      // navigate(routes.adminDashboard)
      // this.setState({done: true})
    })
    .catch((err:any) => {
      toast(err.message, { type: 'error'})
      // this.setState({
      //   hasError: true,
      //   errorMessage: err.response.data.message
      // });
    });

  }



  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">{record.id}</Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Item",
      dataIndex: "itemName",
      sorter: (a: TableData, b: TableData) =>
        a.department.length - b.department.length,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text: string) => (
    //     <>
    //       {text === "Active" ? (
    //         <span
    //           className="badge badge-soft-success d-inline-flex align-items-center"
    //         >
    //           <i className='ti ti-circle-filled fs-5 me-1'></i>{text}
    //         </span>
    //       ):
    //       (
    //         <span
    //           className="badge badge-soft-danger d-inline-flex align-items-center"
    //         >
    //           <i className='ti ti-circle-filled fs-5 me-1'></i>{text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.status.length - b.status.length,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          {/* <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li> */}
                  <button
                    className="btn btn-success btn-sm me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_department"
                    onClick={()=> handleEditclick(record)}

                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </button>
                {/* </li>
                <li> */}
                  <button
                    className="btn btn-danger btn-sm "
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                    onClick={()=> handleDeleteclick(record)}
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </button>
                {/* </li>
              </ul>
            </div>
          </div> */}


        </>
      ),

    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Payroll Items</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">HRM</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Payroll Items
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_department"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Payroll Items
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          {/* {JSON.stringify(form)} */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Payroll Items List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Department</label>
                              <CommonSelect
                                className="select"
                                options={departmentSelect}
                               
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-0">
                              <label className="form-label">Status</label>
                            
                              <CommonSelect
                                className="select"
                                options={activeList}
                               
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z{" "}
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1 active"
                      >
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                      >
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                      >
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                      >
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
                <Table columns={columns} dataSource={payrollItems} Selection={true} />
              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      <>
  {/* Add Department */}
  <div className="modal fade" id="add_department">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Payroll Items</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form >
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Item Name</label>
                  <input type="text" className="form-control" 
                    placeholder="Enter Item Name"
                   name='itemName'
                    value={form.itemName}
                    onChange={handleChange}/>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Item Type</label>
                  <CommonSelect
                                className="select"
                                options={["Allowance", "Deduction"].map((item) => ({label: item, value:item}))}
                                handleChange={(e:any)=>setForm((p:any)=>({...p,itemType:e.value}))}
                               
                              />
                  
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Default Amount</label>
                  <input type="text" className="form-control" 
                    placeholder="Enter Default Amount"
                   name='defaultAmount'
                    value={form.defaultAmount}
                    onChange={handleChange}/>
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Status</h5>
                  <p>Change the Status by toggle </p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm"
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
              Cancel
            </Link>
            <button  className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSubmit}>
              Add Department
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Add Department */}
  {/* Edit Department */}
  <div className="modal fade" id="edit_department">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Edit Department</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form >
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Department Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Department Name"
                    defaultValue="Admin"
                    name='itemName'
                    value={form.itemName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Status</h5>
                  <p>Change the Status by toggle </p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm2"
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
              Cancel
            </Link>
            <button className="btn btn-primary"
            onClick={handleSubmitEdit}
             /*data-bs-dismiss="modal"*/ >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Edit Department */}
  {/* Delete Modal */}
  <div className="modal fade" id="delete-modal">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <form >
          <div className="modal-body text-center">
            <span className="delete-icon">
              <i className="ti ti-trash-x" />
            </span>
            <h4>Confirm Deletion</h4>
            <p>
              You want to delete all the marked items, this cant be undone once
              you delete.
            </p>
            <div className="d-flex justify-content-center">
              <Link
                to="#"
                className="btn btn-light me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </Link>
              <button className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDelete}>
                Yes, Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* /Delete Modal */}
</>

    </div>
  )
}

export default PayrollItems
